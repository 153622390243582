// <<<<<<<<<<<<<<<<<<<GLOBAL VARIABLES>>>>>>>>>>>>>>>>
const menu = $(".menu");
const menuBars = $(".menu_bars");
const menuText = $("#menu-text");
const nav = $("#nav");
const header = $("#header");
const content = $("#content-area");
const year = $(".year");
let current_year = new Date().getFullYear();

// <<<<<<<<<<<<<<<<<<<<<EVENTS>>>>>>>>>>>>>>>>>>>
menu.on("click", removeNav);
year.text(current_year);

// <<<<<<<<<<<<<<<<<<<FUNCTIONS>>>>>>>>>>>>>>>>>>

// Toggle Navigation
function removeNav() {
  menuBars.toggleClass("not--active");
  nav.toggleClass("active");
  header.toggleClass("hide");
  content.toggleClass("hide");

  if (menuBars.hasClass("not--active")) {
    menuText.text("Menu");
  } else {
    menuText.text("Close");
  }
}

// Active class on Nav
function activeNav() {
  $(".main_nav-bar .main_nav-bar-items a").on("click", function () {
    // removing active class on all siblings
    $(this).closest(".main_nav-bar-items").siblings().removeClass("active");
    // adding active class on the selected li
    $(this).closest(".main_nav-bar-items").addClass("active");

    setTimeout(removeNav, 500);
  });
}
activeNav();

// Scroll down Animation
$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
    $(".box").addClass("hide");
  } else {
    $(".box").removeClass("hide");
  }
});
